import { Component, OnInit } from '@angular/core';
import {StatisticsService} from '../../services/statistics/statistics.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  selectedLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

  generalData = [];
  constructor(private statisticsService: StatisticsService, private translate: TranslateService) { translate.use(this.selectedLanguage) }

  topApps = {
    headers: [this.translate.instant('TRANSLATE.STATISTICS.CODE'), 
    this.translate.instant('TRANSLATE.STATISTICS.TYPE'), 
    this.translate.instant('TRANSLATE.STATISTICS.TOTAL'), 
    this.translate.instant('TRANSLATE.STATISTICS.USER')],
    data: []
  };
  recentOrders = {
    headers: [this.translate.instant("TRANSLATE.STATISTICS.NAME"),
    this.translate.instant('TRANSLATE.STATISTICS.EMAIL'), 
    this.translate.instant('TRANSLATE.STATISTICS.ACTIVE'), 
    this.translate.instant('TRANSLATE.STATISTICS.BRAND'), 
    this.translate.instant('TRANSLATE.STATISTICS.COMPANY'), 
    this.translate.instant('TRANSLATE.STATISTICS.COUNTRY')],
    data: []
  };
  ngOnInit() {
    this.getGeneral();
    this.getTopSearch();
    this.getRecentRegistrations();
  }

  getGeneral() {
    this.statisticsService.getGeneral().subscribe((response: any) => {
      console.log(response);
      this.generalData = response;
    });
  }
  getTopSearch() {
    this.statisticsService.getTopSearch().subscribe((response: any) => {
      console.log(response);
      this.topApps.data = response;
    });
  }
  getRecentRegistrations() {
    this.statisticsService.getRecentRegistrations().subscribe((response: any) => {
      console.log(response);
      this.recentOrders.data = response;
    });
  }

}
