import { Component, OnInit } from '@angular/core';
import { SelectionService } from '../../services/selection/selection.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import {CustomNotificationsService} from '../../services/notifications/notifications.service';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent implements OnInit {

  defaultSections;
  feature;
  formBoxes = [];
  preselectedValues = {};

  paramsForm: FormGroup;

  formValues = {};
  constructor(private selectionService: SelectionService,
              private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private notification: CustomNotificationsService) {}

  ngOnInit() {
    this.getItems();
  }

  getItems() {
    this.selectionService.getItems().subscribe((response: any) => {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        if (params) {
          this.preselectedValues = params;
        }
        console.log("getItems", response);
        console.log("queryParams", params);
        this.feature = response;
        this.defaultSections = _.cloneDeep(this.feature);
        this.fillFormValues();
        this.createForm();
        setTimeout(() => {this.setPreviousValues()}, 100);
        //this.setPreviousValues()
      });
    });
  }

  createForm() {
    //var oldFormValues = JSON.parse(localStorage.getItem('selectionByParametersFormValues'));
    //this.formValues = oldFormValues;
    this.paramsForm = this.fb.group(
      this.formValues
    );    

    this.paramsForm.valueChanges.subscribe(data => {
      // console.log(data);
    });
  }

  onSubmit() {
    this.paramsForm['submitted'] = true;
    sessionStorage.setItem("last_search_parameters", JSON.stringify(this.paramsForm.getRawValue()));
    if (this.paramsForm.valid) {
      this.onValidForm();
    } else {
      console.log('error');
      this.notification.message('error', 'Required fields', 'Some fields are not filled');
    }
  }

  saveFormResults(formBox, id) {
    this.formBoxes[id] = formBox;
  }

  setToDefault() {
    this.fillFormValues(true);
    this.paramsForm.setValue(this.formValues);
  }

  maxValue(max) {
    return (input: FormControl) => {
      return input.value <= max ? null : {max: true};
    };
  }

  minValue(min) {
    return (input: FormControl) => {
      return input.value >= min ? null : {max: true};
    };
  }

  setPreviousValues(){
    let tempValues = sessionStorage.getItem("last_search_parameters"); 
    if(tempValues){
      this.paramsForm.setValue(JSON.parse(tempValues));
      console.log(tempValues)
      sessionStorage.removeItem("last_search_parameters"); 
    }
  }

  fillFormValues(defaultValues: Boolean = false) {
    // if(localStorage.getItem('selectionByParametersFormValues') != undefined)
    // {
    //   this.formValues = JSON.parse(localStorage.getItem('selectionByParametersFormValues'));
    //   return;
    // }
    
    for (const featureObject of this.feature.featureObjects) {
      for (const parameter of featureObject.parameters) {
        let value;
        if (defaultValues) {
          value = parameter.defaultOption || '';
          this.formValues[parameter.parameter] = value;
        } else {
          value = this.preselectedValues[parameter.parameter] || (parameter.defaultOption ? parameter.defaultOption : '');
          this.formValues[parameter.parameter] = [value, []];
          if (parameter.required) {
            this.formValues[parameter.parameter][1].push(Validators.required);
          }
          if (parameter.max) {
            this.formValues[parameter.parameter][1].push(this.maxValue(parameter.max));
          }
          if (parameter.min) {
            this.formValues[parameter.parameter][1].push(this.minValue(parameter.min));
          }
        }
      }
    }
  }

  onValidForm() {
    localStorage.setItem('selectionByParametersFormValues', JSON.stringify(this.formValues));
    this.router.navigate(['choose-model'], { queryParams: this.paramsForm.value });
  }

  hasRequiredError() {
    // return this.paramsForm.submitted && this.paramsForm.get(this.name).errors && this.paramsForm.get(this.name).errors.required;
  }

  hasMinMaxError() {
    // return this.paramsForm.submitted && this.paramsForm.get(this.name).errors &&
    //   (this.paramsForm.get(this.name).errors.max || this.paramsForm.get(this.name).errors.min);
  }

}
