import {Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import { ComparisonService } from '../../services/comparison/comparison.service';
import {ModalComponent} from '../../shared/modal/modal.component';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {UnsetComparison, ClearComparison} from '../../store/app.actions';
import { clone } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit {
  selectedLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
  loading = true;

  feature: any = {};
  
  filterSelected = 0;

  showGraph = false;
  graph;
  tables;
  comparisonList: any = [];

  @ViewChild('myModal') myModal: ModalComponent;

  @Select(state => state.app.comparison) comparison$: Observable<any>;

  constructor(private comparisonService: ComparisonService,
              private cd: ChangeDetectorRef,
              private store: Store, private translate: TranslateService) { translate.use(this.selectedLanguage)}

  filters = [{id: 0, name: this.translate.instant('TRANSLATE.COMPARISON.OPERATION')}, 
  {id: 1, name: this.translate.instant('TRANSLATE.COMPARISON.NOMINAL')}, 
  {id: 2, name: this.translate.instant('TRANSLATE.COMPARISON.ERPDATA')}];

  ngOnInit() {
    this.getTabs();
    this.getComparisonList();
    this.loading = false;
  }

  getTabs() {
    this.tables = [];
  }

  getComparisonList() {

    this.graph = undefined;
    this.comparison$.subscribe((comparisonList) => {
      this.comparisonList = comparisonList;
      this.comparisonList.forEach((data, i) => {

        if (i > 0) {
          //this.graph.ypoints = this.graph.ypoints.concat(data.graph.ypoints);
          //this.graph.borderColor = this.graph.borderColor.concat(data.color);
          //this.graph.labels = this.graph.labels.concat(data.graph.labels);
          data.graph.graphs[0].label = data.name;
          data.graph.graphs[0].fanType = data.graph.fanType;
          this.graph.graphs.push(data.graph.graphs[0]);
          this.graph.borderColor.push(data.color);
          //this.graph.graphs[0].fanType = data.graph.fanType;

        } else {
          this.graph = clone(data.graph);
          data.graph.graphs[0].label = data.name;
          data.graph.graphs[0].fanType = data.graph.fanType;
          this.graph.graphs = [data.graph.graphs[0]];

          // this.graph = {
          //   graphs: [clone(data.graph.graphs[0])],
          //   xLabel: data.graph.xLabel,

          // };
        }
      });

      this.comparisonList.forEach((model, i) => {
        if (i === 0) {
          this.tables.push([], [], []);
          model.data.forEach((data, j) => {
            data.data.forEach(obj => {
              this.tables[j].push(obj.name);
            });
          });
        }
      });
    });
  }

  onFilterSelected(event) {
    this.filterSelected = event;
  }

  onDeleteFromComparison (index) {
    this.store.dispatch(new UnsetComparison(index)).subscribe((res) => {
      this.getComparisonList();
    });
    this.myModal.visible = false;
  }

  clearAll() {
    this.store.dispatch(new ClearComparison());
    this.myModal.visible = false;
    this.graph = undefined;
  }

  clearAllConfirm() {
    this.myModal.visible = true;
  }
  cancel() {
    this.myModal.visible = false;
  }
}
