import { ChangeDetectorRef, Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { OperatingPointService } from '../../services/operating-point/operating-point.service';
import { remove, find } from 'lodash';
import { MyProjectsService } from '../../services/my-projects/my-projects.service';
import { CustomNotificationsService } from '../../services/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { randomColor } from '../../helpers/helper';
import { Store } from '@ngxs/store';
import { SetComparison } from '../../store/app.actions';
import { catchError } from 'rxjs/operators';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-operating-point',
  templateUrl: './operating-point.component.html',
  styleUrls: ['./operating-point.component.scss']
})
export class OperatingPointComponent implements OnInit {
  selectedLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
  @Output() isSelected: EventEmitter<{}> = new EventEmitter();
  //
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  onItemSelect (item:any) {
    console.log(item);
  }
  onSelectAll (items: any) {
    console.log(items);
  }
  //

  loading = true;
  feature: any = {};
  showProjectSelection = false;
  projects: any = [];
  operatingPointInputs;
  operationPointId = 0;
  showGraphSelection = true;
  radioButtonSelected =  null;

  graphData;
  graphDataTemp;
  graphs = [];
  secondLabel = '';

  tables = [];
  card = {};
  frequency = {};
  view = 'feature';
  selectedTab = 0;
  inputData = [];
  links = [];

  calculateLoading = true;
  graphLoading = true;
  fanPositionButtonDisabled = true;

  modelsToCompare = [];
  projectId;

  id;
  sections = {
    main: true,
    data: true,
    sound: true
  };

  legend = [];
  types = [
    'static_pressure'
  ];
  limitTypes = 2;

  soundOptions = [
    { id: 1 },
    { id: 2 },
    { id: 4 },
    { id: 8 }
  ];

  lastCalculationParameters;

  additionalOptions = [
    {
      options: [
        {
          id: 0,
          selection: true,
          description: 'Lw'
        }//,
        //{
        //  id: 1,
        //  selection: false,
        //  description: 'Lp'
       // }
      ],
      type: 'typePower'
    },
    {
      options: [
        {
          id: 0,
          selection: true,
          description: 'Octave'
        }//,
        //{
         // id: 1,
         // selection: false,
         // description: '3rd Octave'
        //}
      ],
      type: 'typeOctave'
    },
    {
      options: [
        {
          id: 0,
          selection: true,
          description: 'Weighted'
        }//,
        //{
        //  id: 1,
        //  selection: false,
        //  description: 'Unweighted'
        //}
      ],
      type: 'typeWeighted'
    }
  ];

  graphOptions = {
    requestPressure: 200,
    airFlow: 400,
    rpm: 1,
    power: 1,
    dynamicPressure: 1,
    altitude: 1,
    density: 1,
    frequency: 50,
    temperature: 1,
    humidity: 1,
    pressure: 1,
    fanPosition: 1,
    distance: 1,
    typePower: true,
    typeOctave: true,
    typeWeighted: true,
    chartType: 'static_pressure'
  };

  compArr = [];

  constructor(private operatingPointService: OperatingPointService,
              private projectService: MyProjectsService,
              private zone: NgZone,
              private cd: ChangeDetectorRef,
              private notification: CustomNotificationsService,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private store: Store) {
    this.operationPointId = +localStorage.getItem('operation-point');
    translate.use(this.selectedLanguage);
  }

  ngOnInit() {

    this.dropdownList = [           
      { item_id: 1, item_text: 'Total pressure' },
      { item_id: 2, item_text: 'Power' },
      { item_id: 3, item_text: 'RPM' },
      { item_id: 4, item_text: 'Current' },
      { item_id: 5, item_text: 'Specific fan power' },
      { item_id: 6, item_text: 'Overall static efficiency' },
      { item_id: 7, item_text: 'Total static efficiency' },
      { item_id: 8, item_text: 'Static efficiency' },
  //    { item_id: 9, item_text: 'Sound power (octave)' },
  //    { item_id: 10, item_text: 'Sound power (third octave)' },
  // Next line is commented and added line after something different, we need to test in production this
      //{ item_id: 11, item_text: 'Weighted sound power (octave)' } 
      { item_id: 9, item_text: 'Weighted sound power (octave)' } 
  //    { item_id: 12, item_text: 'Weighted sound power (third octave)' },
   //   { item_id: 13, item_text: 'Sound pressure (octave)' },
   //   { item_id: 14, item_text: 'Sound pressure (third octave)' },
   //   { item_id: 15, item_text: 'Weighted sound pressure (octave)' },
   //   { item_id: 16, item_text: 'Weighted sound pressure (third octave)' }      
    ];
    this.selectedItems = [
      { item_id: 2, item_text: 'Power' },
      { item_id: 5, item_text: 'Specific fan power' },
      { item_id: 9, item_text: 'Sound power (octave)' },
      //{ item_id: 13, item_text: 'Sound pressure (octave)' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.getId(async (id) => {
      this.id = id;
      this.store.select(state => state.app.comparison).subscribe((res) => {
        this.compArr = res;
      });
      this.getFrequency(id);
      this.getCard(id);
      this.getLegend();
      this.getLinks(id);
      await this.getInputs(id);
      this.getProjects();
      this.loading = false;
    });
  }

  getId(cb) {
    this.activatedRoute.params.subscribe((param: Params) => {
      cb(param.id);
    });
  }

  getFrequency(id): void{
    this.operatingPointService.getFrequency(id).subscribe((response: any) => {
      this.frequency = response;
      console.log(response);
    });
  }

  getCard(id): void {
    this.operatingPointService.getCard(id).subscribe((response: any) => {
      this.card = response;
      console.log(response);
    });
  }

  async getGraph(id, types, callCalculate = false): Promise<void> {
    if (types.length > 0) {
      await this.operatingPointService.getGraph(id, types[0], this.getGraphData()).subscribe(async (response: any) => {
        console.log(response);
        this.graphDataTemp = response;
        this.graphDataTemp.secondaryAxis = false;
        this.graphDataTemp.secondaryLabel = "";
        this.graphDataTemp.secondaryColor = "";
        this.graphDataTemp.secondaryUnit = "";  
        this.graphDataTemp.startingAirflow = this.graphOptions.airFlow;
        this.graphDataTemp.startingStaticPressure = this.graphOptions.requestPressure;

        if(this.graphDataTemp.type == 'total_pressure')
        {
          this.operatingPointInputs[1].name = 'Pt';
          this.graphDataTemp.yLabel = 'Pt';
        }

        else
        {
          this.operatingPointInputs[1].name = 'Ps';
          this.graphDataTemp.yLabel = 'Ps';
        }          

        if (types.length > 1) {
          await this.operatingPointService.getGraph(id, types[1], this.getGraphData()).subscribe((res: any) => {
            this.graphDataTemp.secondaryAxis = true;
            this.graphDataTemp.secondaryLabel = res.yLabel;
            this.graphDataTemp.secondaryColor = res.graphs[0].color;
            this.graphDataTemp.secondaryUnit = res.yUnit;

            this.graphDataTemp.graphs.push(res.graphs[0]);

            this.graphData = this.graphDataTemp;
            if(callCalculate)
              this.getCalculate(this.lastCalculationParameters.id, this.lastCalculationParameters.data);
          });
        }
        else{
          this.graphData = this.graphDataTemp;
          if(callCalculate)
            this.getCalculate(this.lastCalculationParameters.id, this.lastCalculationParameters.data);
        }
      });
    } else {
      this.graphData = undefined;
    }

    
    this.notification.message('success', 'Graph', 'Graph calculations finished!');
    setTimeout(() => {
      this.graphLoading = false;
    }, 1000);
  }

  getLegend(): void {
    this.operatingPointService.getLegend().subscribe((response: any) => {
      this.legend = response;
    });
  }

  getGraphData() {
    
    this.inputData.forEach(param => {
      console.log("name: ", param.name);
      switch (param.name) {        
        case 'Q':
          this.graphOptions.requestPressure = param.value;
          console.log("Q: ", param.value);
          break;
        case 'Air flow  (m3/h)':
          this.graphOptions.airFlow = param.value;
          console.log("Air flow: ", param.value);
          break;
        default:
      }      
    });
    return this.graphOptions;
  }

  postCharts(id): void {
    this.operatingPointService.postCharts(id, this.graphOptions).subscribe((response: any) => {
      this.graphs = response;
      this.notification.message('success', 'Sound graph', 'Graph calculations finished!');
    });
  }

  async getInputs(id): Promise<any> {
    await this.operatingPointService.getInputs(id, this.graphOptions).subscribe(async (response: any) => {
      this.operatingPointInputs = response;
      await this.getCalculate(id, response);
    });
  }

  getLinks(id): void {
    this.operatingPointService.getLinks(id).subscribe((response: any) => {
      this.links = response;
    });
  }

  async getCalculate(id, data): Promise<any> {  
    this.lastCalculationParameters = {id, data};
    this.calculateLoading = true;
    var freq = data.freq;
    console.log("this is freq in getCal: ", freq);
    console.log("this is data.inputdata in getCal: ", data.inputData);
    if(data.inputData != undefined)
    {
      data = data.inputData;
    }
    
    console.log("this is op data freq : ",data);
    var previousValidAirflow = Math.ceil(this.graphOptions.airFlow * 10000) / 10000;
    var previousValidStaticPressure = Math.ceil(this.graphOptions.requestPressure * 10000) / 10000;

    this.graphOptions.airFlow = Math.ceil(data[0].defaultValue * 10000) / 10000;
    this.graphOptions.requestPressure =  Math.ceil(data[1].defaultValue * 10000) / 10000;
    if(freq != undefined)
    {
      this.graphOptions.frequency = freq;
    }
    this.graphOptions.altitude = 0;
    if(this.graphData != undefined)
      this.graphOptions.chartType = this.graphData.type;

    if (data[2].subparameter[0].defaultValue && data[2].subparameter[1].defaultValue && data[2].subparameter[2].defaultValue) {
      await this.operatingPointService.getDensity({
        temperature: data[2].subparameter[0].defaultValue,
        humidity: data[2].subparameter[1].defaultValue,
        pressure: data[2].subparameter[2].defaultValue
      }).subscribe((response: any) => {
        this.operatingPointInputs[2].defaultValue = response;
        this.graphOptions.density = response;
      });
    } else {
      this.graphOptions.density = data[2].defaultValue;
    }  

    var isWithinTolerance = false;    
  
    this.operatingPointService.getCalculate(id, this.graphOptions).subscribe((response: any) => {
      if(!response.isWithinTolerance)
      {
        this.notification.message('warn', 'Warning', 'The selected values are out of range');
        this.graphOptions.airFlow = previousValidAirflow;
        this.graphOptions.requestPressure = previousValidStaticPressure;

        this.inputData['Air flow  (m3/h)'] = previousValidAirflow;
        this.inputData['Q'] = previousValidStaticPressure;

        this.operatingPointService.getCalculate(id, this.graphOptions).subscribe((responsePrevious: any) => {   
          this.tables = responsePrevious.tables;
    
          if (this.tables.length !== 0) {
            this.graphOptions.rpm = this.tables[0].data[1].value;
          }                
          
          this.postCharts(id);    

          this.getGraph(id, this.types);
          
          this.graphLoading = false;
          this.calculateLoading = false;       
        });
      }  
      else
      {
        this.tables = response.tables;
  
        if (this.tables.length !== 0) {
          this.graphOptions.rpm = this.tables[0].data[1].value;
        }              
        
        this.postCharts(id);     

        this.getGraph(id, this.types);
        
        this.graphLoading = false;
        this.calculateLoading = false;        
      }    
    });      

    return this.graphOptions;
  }

  onPointSelected(event): void {
    if (!event[0]) {
      event[0] = this.inputData[0];
    }
    this.inputData = [...event];
  }

  getProjects() {
    this.operatingPointService.getProjects().subscribe((response: any) => {
      this.projects = response;
    });
  }

  addToComparisonFunc() {
    if (this.compArr.length < 8) {
      this.getId(async (id) => {
        this.operatingPointService.getGraph(id, ['static_pressure'], this.getGraphData()).subscribe((response: any) => {
          const color = randomColor(this.compArr.length);

          response.borderColor = [color];
          this.store.dispatch(new SetComparison([{
            id: this.modelsToCompare.length,
            name: this.card['name'],
            color: color,
            image: this.card['image'],
            data: this.tables,
            graph: response
          }]));
        });
      });
      this.notification.message('success', 'Success', 'Item added to comparison');
    } else {
      this.notification.message('warn', 'Warning', 'You can select only 8 items for comparison');
    }
  }

  addToProject(event) {
    this.showProjectSelection = false;
    if (event) {
      this.projectService.insertModels(event, {
        model: [this.id]
      }).subscribe((response: any) => {
        this.notification.message('success', 'Success', 'Item added to project');
      });
    }
  }

  createProject(event) {
    if (event) {
      this.showProjectSelection = false;
      this.projectService.createProject({
        projectName: event,
        construction: '',
        address: '',
        purchaser: '',
        projectant: '',
        business: ''
      }).subscribe((response: any) => {
        if (response.projectId) {
          this.projectService.insertModels(response.projectId, {
            model: [this.id]
          }).subscribe((res: any) => {
            this.notification.message('success', 'Success', 'Project created and Item added to project');
            this.getProjects();
          });
        } else {
          this.translate.get(response.message).subscribe((res: string) => {
            this.notification.message(response.messageType === 'error' ? 'warn' : response.messageType, response.messageType, res);
          });
        }
      });
    } else {
      this.notification.message('warn', 'Warning', 'Project name is required');
    }
  }
  
  async onTypeSelected(event) {
    if (event.group === 'left') {
      this.types[0] = event.type
    } else {
      var el = (<HTMLInputElement>document.getElementById(event.id));
      if(this.radioButtonSelected == el)
      {
        
        el.checked = !el.checked;
        if(el.checked){
          this.types[1] = event.type;
        }
        else{
          this.types.splice(1, 1);
          this.radioButtonSelected = null;
        }
      }
      else
      {
        this.radioButtonSelected = el;
        this.types[1] = event.type;
        
      }
      
    }
    
    let calculateAgain = event.type == "static_pressure" || event.type == "total_pressure";
    await this.getId(async (id) => {
      await this.getGraph(id, this.types, calculateAgain);
    });
  }

  downloadCard(link) {
    var selectedCharts = this.selectedItems;
    this.getId((id) => {
      this.operatingPointService.generateCard(id, selectedCharts).subscribe((response: any) => {
        window.open(response, '_blank');
      });
    });
    
  }

  enableFanPositionButtons(selected, option) {
    if (option == "typePower")
      if (selected){
        selected = true;
        this.fanPositionButtonDisabled = true;
        this.graphOptions.fanPosition = 1;
      }       
      else
        this.fanPositionButtonDisabled = false;
  }
}
