import { Component } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CustomNotificationsService } from './services/notifications/notifications.service';
import { SearchByCodeService } from './services/search-by-code/search-by-code.service';
import {UserService} from './services/user/user.service';
import {HelperService} from './services/helper/helper.service';
import { Store } from '@ngxs/store';
import { ClearComparison } from '././store/app.actions';
import { MainService } from './services/main.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  measure = 'metric';
  loggedIn = false;
  isOpenedSearchByCode = false;
  selectedLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

  constructor(private authService: AuthService,
              private router: Router,
              private translate: TranslateService,
              private searchByCodeService: SearchByCodeService,
              private userService: UserService,
              private helper: HelperService,
              private notification: CustomNotificationsService,
              private store: Store,
              private mainService: MainService) {
    this.loggedIn = authService.isLoggedIn();
    translate.use(this.selectedLanguage);

    router.events.subscribe((val) => this.isOpenedSearchByCode = false);
    if (this.helper.getUserId()) {
      this.authService.getUser(this.helper.getUserId()).subscribe((response: any) => {
        authService.setPermissions({
          admin: response.admin,
          comparison: response.comparison
        });
        this.measure = response.metricSystem;
      });
    }
  }

  onChangeMetrics(event): void {
    this.userService.updateMetricSystem(event).subscribe((response: any) => {
      this.measure = event;
      window.location.reload();
      this.store.dispatch(new ClearComparison());
    });
  }

  onChangeLanguage(event) {
    this.selectedLanguage = event;
    this.mainService.post("changelang/" + this.selectedLanguage).subscribe();
    window.location.reload();
    localStorage.setItem('lang', event);
    this.translate.use(event);
  }

  searchByCode(event) {
    if (event.status && event.value !== '') {
      this.searchByCodeService.search(event.value).subscribe((response: any) => {
        if (response.length !== 0) {
          
          var parameter = {
            searchByCode: true,
            models: response.map(model => {
              var rObj = {
                modelId: model.PK,
                modelCode: model.Code,
                modelType: model.ModelType
              };

              return rObj;              
            })
          };

          var parameterString = JSON.stringify(parameter);

          this.router.navigate([`/choose-model/${parameterString}`]);
        } else {
          this.notification.message('warn', 'Info', 'There are no items for searched code');
        }
      });
    }
    this.isOpenedSearchByCode = !this.isOpenedSearchByCode;
  }
}
