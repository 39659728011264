import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ModalComponent} from '../../shared/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  selectedLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
  
  userId;
  search = '';

  @ViewChild('myModal') myModal: ModalComponent;

  constructor(private userService: UserService, private translate: TranslateService) { translate.use(this.selectedLanguage) }

  users = {
    headers: [this.translate.instant('TRANSLATE.USERS.NAME'), 
    this.translate.instant('TRANSLATE.USERS.EMAIL'), 
    this.translate.instant('TRANSLATE.USERS.USER_GROUP'), 
    this.translate.instant('TRANSLATE.USERS.REGISTERED'), 
    this.translate.instant('TRANSLATE.USERS.LAST_ACTIVE')],
    data: []
  };

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.userService.getItems().subscribe((response: any) => {
      this.users.data = response;
    });
  }

  confirmDeleteUser(id) {
    this.userId = id;
    this.myModal.visible = true;
  }
  onDeleteUser() {
    this.userService.deleteUser(this.userId).subscribe((response: any) => {
      this.getUsers();
      this.myModal.visible = false;
    });
  }
  cancelDelition() {
    this.userId = null;
    this.myModal.visible = false;
  }
}
