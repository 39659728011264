import { Component, EventEmitter, Input, Output } from '@angular/core';
import Chart from 'chart.js';
import * as $ from 'jquery';
import { isEmpty, cloneDeep } from 'lodash';
import { generate } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chart-area-component',
  template: `
    <div style="background: #fff !important">
      <canvas [id]="canvasId" height="200"></canvas>
    </div>
  `,
  styleUrls: ['./chart-area.component.css']
})
export class ChartAreaComponent {

  constructor(private translate: TranslateService) {}

  chartData;
  areaChart;
  maxRight = 10;

  @Input() canvasId: string;
  @Input() secondLabel = 'B';
  @Input() secondLine = true;
  @Input() showYColors = false;
  @Input() interactive: boolean;
  @Input() type = 'sound';
  @Input() set chartSetData(data) {
    if (data) {
      this.chartData = data;
      setTimeout(() => {
        this.generateGraph();
      }, 1000);
    }
  }


  @Output() points: EventEmitter<Array<string>> = new EventEmitter();

  generateGraph() {
    const canvas = <HTMLCanvasElement> document.getElementById(this.canvasId);
    
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    const input1 = document.getElementById('input1');

    const data = this.getGraphData();
    var chartData = this.chartData;
    const self = this;  

    var options = this.getOptions();

    if (!this.areaChart) {
      this.areaChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: options,
      });          
     }

    this.areaChart.selectedPointX = this.chartData.startingAirflow;
    this.areaChart.selectedPointY = this.chartData.startingStaticPressure;    
    
    if(chartData.fanType == "AC"){
      var graphlength = chartData.graphs[0].points.length;
      var xmin = chartData.graphs[0].points[0].X;
      var xmax = chartData.graphs[0].points[graphlength - 1].X;

      if(this.areaChart.selectedPointX == 0)
      {
        this.areaChart.selectedPointX = parseInt(chartData.Airflow);
      }
      //console.log("Entered: "+ xmin+" : " + xmax);
      var tempGraphPoints = chartData.graphs[0].points;
      if(chartData.graphs[0].stallPoint != null && chartData.graphs[1].stallPoint != null && chartData.graphs[0].type == chartData.graphs[1].type)
        tempGraphPoints = chartData.graphs[0].points.concat(chartData.graphs[1].points);
      for(var i = 0; i < tempGraphPoints.length; i++)
        {
          if(i >= tempGraphPoints.length - 1)
              break; 

          if(this.areaChart.selectedPointX < tempGraphPoints[i].X)
          {  
            //console.log("test for x: ",this.areaChart.selectedPointX," : " ,chartData.graphs[0].points[i].X);
            var x1 = tempGraphPoints[i - 1].X;
            var x2 = tempGraphPoints[i].X;
            var y1 = tempGraphPoints[i - 1].Y;
            var y2 = tempGraphPoints[i].Y;

            var k = (y1 - y2) / (x1 - x2);
            var n = y1 - k * x1;

            this.areaChart.selectedPointY = k * this.areaChart.selectedPointX + n;
            break;
          }
        }
    }

      self.points.emit([(Math.round(this.areaChart.selectedPointX * 100) / 100).toString(), (Math.round(this.areaChart.selectedPointY * 100) / 100).toString()]);


    this.areaChart.options.scales.yAxes[1].display = chartData.secondaryAxis;
    this.areaChart.options.scales.yAxes[1].scaleLabel.display = chartData.secondaryAxis;
    this.areaChart.options.scales.yAxes[1].scaleLabel.labelString = chartData.secondaryLabel + ' ' + chartData.secondaryUnit; 
    this.areaChart.options.scales.yAxes[0].scaleLabel.labelString = chartData.yLabel + ' ' + chartData.yUnit;  
    
    this.areaChart.data = data;

    var newColor = "#1D4686";
      var newDataset = {
        mytype: 'Working point',
        label: 'Working point',
        xUnit: 'm3/s',
        yUnit: 'Pa',
        xLabel: 'Q: ',
        yLabel: 'P: ',
        xValue: this.areaChart.selectedPointX,
        yValue:this.areaChart.selectedPointY,
        backgroundColor: newColor,
        borderColor: newColor,
        data: [],
        pointRadius: 4,
        hoverRadius: 4,
        fill: false
      }

    if(this.areaChart.config.data.datasets[this.areaChart.config.data.datasets.length - 1].mytype === 'Working point')
      this.areaChart.config.data.datasets.pop();

    newDataset.data.push({x: this.areaChart.selectedPointX, y: this.areaChart.selectedPointY});
    this.areaChart.config.data.datasets.push(newDataset);
      
    this.areaChart.update();    

    const ctx1 = $("#" + this.canvasId);

    var localChart = this.areaChart;

    ctx1.click(function(evt) {     

      var ytop = localChart.chartArea.top;
      var ybottom = localChart.chartArea.bottom;
      var ymin = localChart.scales['A'].min;
      var ymax = localChart.scales['A'].max;
      var newy = 0;
      var showstuff = 0;
      if (evt.offsetY <= ybottom && evt.offsetY >= ytop) {
        newy = Math.abs((evt.offsetY - ytop) / (ybottom - ytop));
        newy = (newy - 1) * -1;
        newy = newy * (Math.abs(ymax - ymin)) + ymin;
        showstuff = 1;
      }
      var xtop = localChart.chartArea.left;
      var xbottom = localChart.chartArea.right;
      var xmin = localChart.scales['x-axis-0'].min;
      var xmax = localChart.scales['x-axis-0'].max;
      var newx = 0;
      if (evt.offsetX <= xbottom && evt.offsetX >= xtop && showstuff == 1) {
        newx = Math.abs((evt.offsetX - xtop) / (xbottom - xtop)); 
        newx = newx * (Math.abs(xmax - xmin)) + xmin;
      }

      if(newy == 0 && newx == 0)
        return;
     
      let index = 0
      let points = chartData.graphs[0].points;
      if(chartData.graphs[0].stallPoint != null && chartData.graphs[1].stallPoint != null && chartData.graphs[0].type == chartData.graphs[1].type)
        points = points.concat(chartData.graphs[1].points)
      console.log(points);
      for(var i = 0; i < points.length; i++)
      {
        if(i >= points.length - 1)
            return;

        if(newx < points[i].X)
        {  
          var x1 = points[i - 1].X;
          var x2 = points[i].X;
          var y1 = points[i - 1].Y;
          var y2 = points[i].Y;

          var k = (y1 - y2) / (x1 - x2);
          var n = y1 - k * x1;

          var yCurve = k * newx + n;

          if(chartData.fanType == "EC" && newy > yCurve * 1.01)
            return;
          else if(newy > yCurve)
            newy = yCurve;

          if(chartData.fanType == "AC")
          {
            if(newy < yCurve * 0.9 || newy > yCurve * 1.1)
              return;
            else
              newy = yCurve;
          }
          console.log(newx, newy)

          break;
        }
      }    

      var newColor = "#1D4686";
      var newDataset = {
        mytype: 'Working point',
        label: 'Working point',
        xUnit: 'm3/s',
        yUnit: 'Pa',
        xLabel: 'Q: ',
        yLabel: 'P: ',
        xValue: newx,
        yValue: newy,
        backgroundColor: newColor,
        borderColor: newColor,
        data: [],
        pointRadius: 4,
        hoverRadius: 4,
        fill: false
      }

      if(localChart.config.data.datasets[localChart.config.data.datasets.length - 1].mytype === 'Working point')
        localChart.config.data.datasets.pop();

      newDataset.data.push({x: newx, y: newy});
      localChart.config.data.datasets.push(newDataset);

      localChart.selectedPointX = newx;
      localChart.selectedPointY = newy;

      localChart.update();  
      self.points.emit([(Math.round(localChart.selectedPointX * 100) / 100).toString(), (Math.round(localChart.selectedPointY * 100) / 100).toString()]);
    });     
  }

  getGraphData() {
    const data: any = {};
    data.datasets = [];
    var fanType = this.chartData.fanType; 
    var xUnit = this.chartData.xUnit; 
    var xLabel = this.chartData.xLabel;
    var hasStallPoint = false;
    
    var translate = this.translate;
    var stallPoint;
    
    for(let i=0; i<this.chartData.graphs.length; i++){
      if(this.chartData.graphs[i].type === 'static_pressure' || this.chartData.graphs[i].type === 'total_pressure')
         stallPoint = JSON.parse(JSON.stringify(this.chartData.graphs[i].stallPoint));
      if(this.chartData.graphs[i].type === 'power' && stallPoint){
        this.chartData.graphs[i].stallPoint = stallPoint;
      }
      if(this.chartData.graphs[i].stallPoint != null){
        let j=0;
        while(j<this.chartData.graphs[i].points.length && this.chartData.graphs[i].points[j].X < this.chartData.graphs[i].stallPoint.X) j++;

        let tempPoints = this.chartData.graphs[i].points.splice(0, j);
        let tempPoints2 = this.chartData.graphs[i].points;

        var tempStallPoint = this.chartData.graphs[i].stallPoint;
        //console.log(this.chartData.graphs[i].type)
        if(this.chartData.graphs[i].type == 'power'){
          var coeff = ( tempStallPoint.X - tempPoints[tempPoints.length - 1].X ) / ( tempPoints2[0].X - tempPoints[tempPoints.length - 1].X );
          tempStallPoint.Y = tempPoints[tempPoints.length - 1].Y + (tempPoints2[0].Y - tempPoints[tempPoints.length - 1].Y) * coeff;
        }

        tempPoints.push(tempStallPoint);
        tempPoints2.splice(0, 0, tempStallPoint);
        
        let newGraph = JSON.parse(JSON.stringify(this.chartData.graphs[i]));
        newGraph.points = tempPoints2;

        this.chartData.graphs[i].points = tempPoints;
        this.chartData.graphs.splice(i+1, 0, newGraph);
        i++;
      }
    }

    this.chartData.graphs.forEach((graph) =>
    {
      const dataValues = graph.points.map((point) => ({
        x: point.X,
        y: point.Y
      }));

      var translator = translate.translations[(translate.currentLang)]['TRANSLATE'];
      var translatedVal = translator[graph.label.replace('TRANSLATION.', '')];

      graph.label = translatedVal;

      var axisID = 'A';
     
      if(graph.type != 'static_pressure' && graph.type != 'total_pressure' && graph.type != 'op_curve' && graph.type != 'resistance')      
        axisID = 'B';

      var fillArea = false;

      if(fanType === 'EC' && (graph.type === 'static_pressure' || graph.type === 'total_pressure'))
        fillArea = true;

      var borderDash = [5, 0];

      if((graph.type === 'static_pressure' || graph.type === 'total_pressure') && fanType === 'EC')
        borderDash = [5, 5];

      var yLabel = "Ps";

      if(graph.type === 'total_pressure')
       yLabel = "Pt";

      if(graph.stallPoint){
        if(hasStallPoint){
          borderDash = [5, 0];
          hasStallPoint = false;
        }
        else{
          borderDash = [5, 5];
          hasStallPoint = true;
        }
      }
      else
        hasStallPoint = false;

      data.datasets.push(
        {
          mytype: graph.type,
          label: graph.label,
          data: dataValues,
          xUnit: xUnit,
          yUnit: graph.yUnit,
          yAxisID: axisID,
          borderColor: graph.color,
          fill: fillArea,
          xLabel: xLabel,
          yLabel: yLabel,
          borderDash: borderDash,
          pointStyle: 'line' 
        });
    });

    console.log("Datasets", data.datasets);
    return data;
  }

  getOptions() {
    const self = this;
    {
      return {
        animation: false,
        legend: {
          display: true,
          labels: {
             filter: function (legendItem, data) {
                if(legendItem.lineDash && legendItem.lineDash[1])
                  return legendItem.lineDash[1] == 0;
                return true;
             },
             usePointStyle: true
          }
        },
        elements: {
          point: {
            radius: 0
          },
          line: {
            borderWidth: 2
          }
        },
        scales: {
          yAxes: [{
            id: 'A',
            position: 'left',
            ticks: {
              min: 0,
              fontColor: self.chartData.graphs[0].color
            },
            scaleLabel: {
              display: true,
              labelString: self.chartData.yLabel + self.chartData.yUnit
            }
          }, {
              id: 'B',
              display: true,              
              position: 'right',
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
              ticks: {
                min: 0,
                fontColor: '#e1cb00'
              },
              scaleLabel: {
                display: true,
                labelString: self.chartData.secondaryUnit + (self.chartData.secondaryLabel)
              }
            }
          ],
          xAxes: [{
            type: 'linear',
            ticks: {
              beginAtZero: true,
              maxRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: self.chartData.xLabel + self.chartData.xUnit
            }
          }]
        },
        tooltips: {
          mode: 'point',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) { return; }
            tooltip.displayColors = false;
          },
          callbacks: {
            label: function(tooltipItem, data) {
              if(data.datasets[tooltipItem.datasetIndex].mytype == 'Working point'){
                return [ 
                  `${data.datasets[0].xLabel} : ${Math.round(data.datasets[tooltipItem.datasetIndex].xValue* 100) / 100} ${data.datasets[0].xUnit}`,
                  `${data.datasets[0].yLabel} : ${Math.round(data.datasets[tooltipItem.datasetIndex].yValue * 100) / 100} ${data.datasets[0].yUnit}`                 
                  // `${data.datasets[tooltipItem.datasetIndex].xLabel} ${data.datasets[tooltipItem.datasetIndex].xUnit}: ${Math.round(data.datasets[tooltipItem.datasetIndex].xValue)}`,
                  // `${data.datasets[tooltipItem.datasetIndex].yLabel} ${data.datasets[tooltipItem.datasetIndex].yUnit}: ${Math.round(data.datasets[tooltipItem.datasetIndex].yValue)}`
                ];
              }

              return [
                `Curve: ${data.datasets[tooltipItem.datasetIndex].label}`,
                `${data.datasets[tooltipItem.datasetIndex].xLabel} : ${Math.round(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x * 100) / 100} ${data.datasets[tooltipItem.datasetIndex].xUnit}`,
                `${data.datasets[tooltipItem.datasetIndex].yLabel} : ${Math.round(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y * 100) / 100} ${data.datasets[tooltipItem.datasetIndex].yUnit}`
              ];
            },
            title: function(tooltipItem, data) {
              return;
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },        
      };
    }
  }
}
