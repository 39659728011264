import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if(args[0] == "registered" || args[0] == "inscrit"|| args[0] == "registrato"|| args[0] == "eingetragen" )
    {
      var compare = ["seconds", "minute", "minutes", "hour", "hours", "day", "days", "month", "months", "year", "years"];
      var returnValue = [-1, 0, 1];
      if (args[1] == false){
        returnValue = [1, 0, -1];
      }

      return value.sort(function(a,b)
      {
        var alist = a.registered.split(" ");
        var blist = b.registered.split(" ");
        if(alist.length == 2)
          return returnValue[0];
        else if(blist.length == 2)
          return returnValue[2];
        else if(compare.indexOf(alist[2]) < compare.indexOf(blist[2]))
          return returnValue[0];
        else if(compare.indexOf(alist[2]) > compare.indexOf(blist[2]))
          return returnValue[2];
        else if(parseInt(alist[1]) < parseInt(blist[1]))
          return returnValue[0];
        else if(parseInt(alist[1]) > parseInt(blist[1]))
          return returnValue[2];
        return returnValue[1];

      });
    }
    return orderBy(value, [args[0]], [args[1] ? 'asc' : 'desc']);
  }
}

