import { Component, OnInit } from '@angular/core';
import { CustomNotificationsService } from 'src/app/services/notifications/notifications.service';
import { OperatingPointService } from 'src/app/services/operating-point/operating-point.service';
import { SearchByCodeService } from 'src/app/services/search-by-code/search-by-code.service';

@Component({
  selector: 'app-operating-instructions',
  templateUrl: './operating-instructions.component.html',
  styleUrls: ['./operating-instructions.component.scss']
})
export class OperatingInstructionsComponent implements OnInit {

  constructor(private operatingPointService: OperatingPointService, 
    private searchByCodeService: SearchByCodeService, private notification: CustomNotificationsService) { }

  regex = /\d{4}-\d-\d{4}/;

  ngOnInit() {
  }

  searchByCode(code:string): void{
    this.searchByCodeService.searchByCode(code).subscribe((response: any) => {
      if(response.PK == 0){
        if(this.regex.test(code) && code.length == 11 && (code.startsWith("0309-4-0") || 
          code.startsWith("0309-4-1999") || code.startsWith("0311-4-") || code.startsWith("0313-4-")))
          this.operatingPointService.getOperatingPointLink().subscribe((response: any) => {
            window.open(response[0].link, "_blank");
          });
        else
          this.notification.message('error', 'Fan', 'No fan with such code in database');
      }
      else
        this.getLinks(response.PK);
    });
  }

  getLinks(id): void {
    this.operatingPointService.getLinks(id).subscribe((response: any) => {
      response = response.filter(el => el.name == "TRANSLATE.OPERATING-POINT.INSTRUCTIONS");
      if(response.length == 0)
        this.notification.message('warning', 'Fan', 'No instructions for this fan');
        //alert("No instructions for this fan");
      else
        window.open(response[0].link, "_blank");
    });
  }
}
