import {Component, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-frequency-info',
  templateUrl: './frequency-info.component.html',
  styleUrls: ['./frequency-info.component.scss']
})
export class FrequencyInfoComponent {
  @Input() item;
  @Input() inputData;
  @Output() calculate: EventEmitter<any> = new EventEmitter();

  constructor() {
  }
  changeClick(value:string) {
    //this.calculate.emit(this.item);
    var lengthfreq =this.inputData.length +1;
    console.log("length: ",lengthfreq);

    //this.inputData[lengthfreq].defaultValue = value;
    var inputdata = this.inputData;
    this.calculate.emit({inputData:inputdata,freq:value});
  
  }

}


